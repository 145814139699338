/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable fp/no-let,quote-props */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */

import {
    PRICE_SLIDER_UPDATE_TIMEOUT_TIME
} from 'Component/CategoryConfigurableAttributes/CategoryConfigurableAttributes.config';
import ExpandableContent from 'Component/ExpandableContent';
import MultiRangeSlider from 'Component/MultiRangeSlider';
import ProductAttributeValue from 'Component/ProductAttributeValue/ProductAttributeValue.component';
// eslint-disable-next-line max-len
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './CategoryConfigurableAttributes.style';

/** @namespace Pwa/Component/CategoryConfigurableAttributes/Component/CategoryConfigurableAttributesComponent */
export class CategoryConfigurableAttributesComponent extends ProductConfigurableAttributes {
    renderPriceSwatch(option) {
        const {
            getSubHeading,
            handleOptionClick
        } = this.props;

        const {
            attribute_label,
            attribute_code,
            attribute_options,
            ...priceOption
        } = option;

        let minimum = 0;
        let maximum = 100;
        let setPriceRangeValuesTimeout = 0;
        let currentMinimum = 0;
        let currentMaxmimum = 0;

        if (attribute_options) {
            // do not render price filter if it includes "*_*" aggregation
            if (attribute_options['*_*']) {
                return null;
            }

            Object.entries(attribute_options).forEach((attribute_option) => {
                const fromTo = attribute_option[0].split('_');
                const from = Number.parseInt(fromTo[0], 10);
                const to = Number.parseInt(fromTo[1], 10);
                minimum = from < minimum ? from : minimum;
                maximum = to > maximum ? to : maximum;

                // eslint-disable-next-line no-restricted-globals
                if (isNaN(to)) {
                    // eslint-disable-next-line no-restricted-globals
                    if (isNaN(to)) {
                        maximum *= 2;
                    } else {
                        maximum += to;
                    }
                }
            });
        }

        function setPriceRangeValues({ min, max }) {
            setPriceRangeValuesTimeout = 0;

            // first set
            if (!currentMinimum && !currentMaxmimum) {
                currentMinimum = min;
                currentMaxmimum = max;
                return;
            }

            // same values
            if (currentMinimum === min
                && currentMaxmimum === max) {
                return;
            }

            handleOptionClick({
                attribute_code: 'price',
                attribute_value: `${min}_${max}`
            });
        }

        function priceRangeValuesChange(min, max) {
            if (setPriceRangeValuesTimeout) {
                clearTimeout(setPriceRangeValuesTimeout);
                setPriceRangeValuesTimeout = 0;
            }
            setPriceRangeValuesTimeout = setTimeout(() => {
                setPriceRangeValues(min, max);
            }, PRICE_SLIDER_UPDATE_TIMEOUT_TIME);
        }

        priceOption.attribute_type = 'slider';

        // const isFilterPriceActive = !selectedFilter.customFilters.price;
        const { selectedFilters, isContentExpanded } = this.props;
        return (
            <ExpandableContent
                key={attribute_code}
                heading={attribute_label}
                subHeading={getSubHeading(option)}
                mix={{
                    block: 'ProductConfigurableAttributes',
                    elem: 'Expandable'
                }}
                isContentExpanded={isContentExpanded}
            >
                <div block="PriceRange" elem="Group" className="PriceRange-Wrapper">
                    <MultiRangeSlider
                        min={minimum}
                        max={maximum}
                        onChange={priceRangeValuesChange}
                        selectedFilters={selectedFilters}
                    />
                </div>
            </ExpandableContent>
        );
    }

    renderDropdownOrSwatch(option) {
        const {
            getSubHeading,
            isContentExpanded
        } = this.props;

        const {
            attribute_label,
            attribute_code,
            attribute_options
        } = option;

        const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
        const isSwatch = !!swatch_data;

        let renderType;
        if (isSwatch) {
            renderType = this.renderSwatch(option);
        } else {
            renderType = this.renderDropdown(option);
        }
        return (
            <ExpandableContent
                key={attribute_code}
                heading={attribute_label}
                subHeading={getSubHeading(option)}
                mix={{
                    block: 'ProductConfigurableAttributes',
                    elem: 'Expandable'
                }}
                isContentExpanded={isContentExpanded}
            >
                {renderType}
            </ExpandableContent>
        );
    }

    renderSlider(option) {
        const { attribute_values } = option;

        return (
            <div
                block="ProductConfigurableAttributes"
                elem="SwatchList"
            >
                {attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                ))}
            </div>
        );
    }


    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            // getLink,
            isSelected
        } = this.props;

        const { attribute_value } = attribute;
     

        return (
            <ProductAttributeValue
                key={attribute_value}
                attribute={attribute}
                isSelected={isSelected(attribute)}
                isAvailable={getIsConfigurableAttributeAvailable(attribute)}
                onClick={handleOptionClick}
            // getLink={ getLink }
            />
        );
    }

    renderConfigurableOption = (option) => {
        const { attribute_code } = option;

        switch (attribute_code) {
            case 'price':
                return this.renderPriceSwatch(option);
            case 'category_id':
                return null;
            default:
                return this.renderDropdownOrSwatch(option);
        }
    };

    renderConfigurableAttributes() {
        const { configurable_options } = this.props;

        return Object.values(configurable_options)
            .map(this.renderConfigurableOption);
    }

    renderSwatch(option) {
        const { attribute_values } = option;

        return (
            <div
                block="ProductConfigurableAttributes"
                elem="SwatchList"
            >
                {attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                ))}
            </div>
        );
    }

    renderDropdown(option) {
        const { attribute_values } = option;

        return (
            <div
                block="ProductConfigurableAttributes"
                elem="DropDownList"
            >
                {attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                ))}
            </div>
        );
    }

    renderFilterByAttributeSet() {
        const {
            isAttributeSetSelected,
            setAttributeSetFilter,
            getCategoryAttributeSets,
            getCategoryAttributeName
        } = this.props;

        const count = getCategoryAttributeSets().length;
        if (count < 2) {
            return <></>;
        }

        return (
            <div className="FilterByAttributeSetContainer">
                {Object.values(getCategoryAttributeSets()).map((categoryAttributeSet) => {
                    const label = getCategoryAttributeName(categoryAttributeSet);
                    const className = isAttributeSetSelected(categoryAttributeSet) ? '' : 'selected';

                    return (
                        <button
                            key={`FilterByAttributeSetContainer${label}`}
                            className={`Button ${className}`}
                            onClick={() => {
                                setAttributeSetFilter(categoryAttributeSet);
                            }}
                        >
                            <span>
                                {label}
                            </span>
                        </button>
                    );
                })}
            </div>
        );
    }

    render() {
        const { isReady, mix } = this.props;

        return (
            <div
                block="ProductConfigurableAttributes"
                mods={{ isLoading: !isReady }}
                mix={mix}
            >
                {isReady && this.renderFilterByAttributeSet()}
                {this.renderConfigurableAttributes()}
                {/* {isReady ? this.renderConfigurableAttributes() : this.renderPlaceholders()} */}
            </div>
        );
    }
}

export default CategoryConfigurableAttributesComponent;
