/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';

import { ResetButtonComponentProps } from './ResetButton.type';

import './ResetButton.style';

/** @namespace Component/ResetButton/Component */
export class ResetButtonComponent extends PureComponent {
    static defaultProps = {
        mix: {},
    };

    __construct(props) {
        super.__construct?.(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { onClick, resetFilters } = this.props;

        onClick();
        resetFilters();
        scrollToTop();
    }



    render() {
        const { mix, isContentFiltered } = this.props;

        return (
            <div
                block="ResetButton"
                mix={mix}
            >
                <button
                    onClick={this.onClick}
                    block="ResetButton"
                    elem="Button"
                    mix={{
                        block: 'Button',
                        mods: { isHollow: true },
                    }}
                >
                    {__('Reset all')}
                </button>
            </div>
        );
    }
}

export default ResetButtonComponent;
