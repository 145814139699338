/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-magic-numbers */
import ProductList from '@scandipwa/scandipwa/src/component/ProductList/ProductList.component';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import { FILTER_TAG_PRODUCT_CARD_PAGE_SIZE } from 'Component/ProductCard/ProductCard.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer
} from 'SourceComponent/ProductList/ProductList.container';
import {
    setEndPageSubCategoryFiltered,
    setStartPageSubCategoryFiltered
} from 'Store/TotalItemsSubCategoryFiltered/TotalItemsSubCategoryFiltered.action';
import { bindReloadProducts, unbindReloadProducts } from 'Util/reloadProductsEvent';
import { getQueryParam, setQueryParams } from 'Util/Url';
import { scrollToTop } from 'Util/Browser';
import ProductListInfoDispatcher from 'Store/ProductListInfo/ProductListInfo.dispatcher';
import { Page } from 'Component/Header/Header.config';

export const McRealTimePromoDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/McRealTimePromo/McRealTimePromo.dispatcher'
);

/** @namespace Pwa/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeCode: state.ConfigReducer.code,
    subCategorySelected: state.SubCategoryFilterTagsReducer.subCategorySelected,
    totalItemsSubCategoryFiltered: state.TotalItemsSubCategoryFilteredReducer.totalItemsSubCategoryFiltered
});

/** @namespace Pwa/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updatePromo: (skus, storeCode) => McRealTimePromoDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updatePromo(skus, storeCode, dispatch)
    ),
    setStartPageSubCategoryFiltered: (total) => dispatch(setStartPageSubCategoryFiltered(total)),
    setEndPageSubCategoryFiltered: (total) => dispatch(setEndPageSubCategoryFiltered(total)),
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options),
});

/** @namespace Pwa/Component/ProductList/Container/ProductListContainer */
export class ProductListContainer extends SourceProductListContainer {
    static propTypes = {
        ...SourceProductListContainer.propTypes,
        storeCode: PropTypes.string.isRequired,
        updatePromo: PropTypes.func.isRequired,
        subCategorySelected: PropTypes.string,
        totalItemsSubCategoryFiltered: PropTypes.number,
        setStartPageSubCategoryFiltered: PropTypes.string.isRequired,
        setEndPageSubCategoryFiltered: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...SourceProductListContainer.props,
        noAttributes: false,
        noVariants: false,
        isWidget: false,
        pageSize: 24
    };

    updatePage(pageNumber) {
        const { location, history } = this.props;

        setQueryParams({
            page: pageNumber === 1 ? '' : pageNumber
        }, location, history);
    }

    // _getPageFromUrl(url = '') {
    //     // const { location } = this.props;
    //     // return +(getQueryParam('page', location) || 1);
    //     const { location: currentLocation } = this.props;
    //     const location = url || currentLocation;

    //     return +(getQueryParam('page', location || '') || 1);
    // }

    _getPages() {
        const { pages } = this.props;
        const { promoPages } = this.state;

        return (promoPages.length > 0) ? promoPages : pages;
    }

    // containerProps = () => ({
    //     currentPage: this._getPageFromUrl(),
    //     isShowLoading: this._isShowLoading(),
    //     isVisible: this._isVisible(),
    //     requestPage: this.requestPage,
    //     isInfiniteLoaderEnabled: false
    // });

    updatePromoPage = () => {
        const { pages, storeCode, updatePromo } = this.props;
        const skus = Object.values(pages).reduce((acc, data) => acc.concat(data.map((item) => item.sku)), []);
        if (skus.length > 0) {
            updatePromo(skus, storeCode);
        }
    };

    reloadPage = (_e) => {
        this.requestPage();
    };

    extractSkus = (pages) => Object.values(pages).reduce((acc, data) => acc.concat(data.map((item) => item.sku)), []);

    componentDidMount() {
        this.refreshInterval = setInterval(() => this.updatePromoPage(), 30000);
        bindReloadProducts(this.reloadPage);
        const { pages, isPreventRequest, subCategorySelected } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            this.setState({ pagesCount: pagesLength });
        }

        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isPreventRequest) {
            /* if tag is selected, request page 1 in graphql */
            if (typeof subCategorySelected === 'string') {
                this.requestPage();
            } else {
                this.requestPage(this._getPageFromUrl());
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { pages } = this.props;
        const { pages: oldPages } = prevProps;
        const skus = this.extractSkus(pages);
        const oldSkus = this.extractSkus(oldPages);

        const diff = skus.filter((x) => !oldSkus.includes(x));
        if (skus.length > 0 && diff.length > 0) {
            this.updatePromoPage();
        }

        super.componentDidUpdate(prevProps);

        const {
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const {
            search,
            filter,
            device
        } = this.props;

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        const isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || currentPage !== prevPage;
        if (device.isMobile && (search !== prevSearch || isProductListUpdated)) {
            this.requestPage(this._getPageFromUrl());
            // scrollToTop();
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        unbindReloadProducts(this.reloadPage);
    }

    _getIsInfiniteLoaderEnabled() {
        return false;
    }

    setPageInterval(pageSelected) {
        const { setStartPageSubCategoryFiltered, setEndPageSubCategoryFiltered } = this.props;
        let startPage;
        let endPage;

        if (typeof pageSelected === 'number') {
            const factor = pageSelected - 1;
            if (factor === 0) {
                startPage = 0;
                endPage = FILTER_TAG_PRODUCT_CARD_PAGE_SIZE;
            }
            if (factor > 0) {
                startPage = (FILTER_TAG_PRODUCT_CARD_PAGE_SIZE * factor) + factor;
                endPage = (FILTER_TAG_PRODUCT_CARD_PAGE_SIZE * pageSelected) + factor;
            }
            setStartPageSubCategoryFiltered(startPage);
            setEndPageSubCategoryFiltered(endPage);

            this.setState({ filterTagSelectedPage: pageSelected });
            window.scrollTo(0, 0);
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        this.state = {
            ...super.state,
            filterTagSelectedPage: 1
        };

        this.setPageInterval = this.setPageInterval.bind(this);
    }

    isEmptyFilter() {
        const { filter } = this.props;

        const validFilters = Object.entries(filter).filter(([key, value]) => {
            switch (key) {
                case 'priceRange':
                    return (value).min > 0 || (value).max > 0;
                case 'customFilters':
                    return Object.keys(value).length > 0;
                case 'categoryIds':
                default:
                    return true;
            }
        });

        /**
         * If there is more then one valid filter, filters are not empty.
         */
        return validFilters.length > 0;
    }

    requestPage(currentPage = 1, isNext = false, isOnlySortUpdated = false) {

        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device,
            location: { pathname },
        } = this.props;

        const { isPrefetchValueUsed } = window;

        const isSearch = pathname.includes(Page.SEARCH);
        const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch;

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                filter,
                search,
                pageSize,
                currentPage,
            },
        };
        if (filter.categoryIds === -1) {
            return;
        }
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };
        if (!isPrefetched) {
            requestProductList(options);
        }

        if (!isWidget) {
            if (!isOnlySortUpdated) {
                waitForPriorityLoad().then(
                    /** @namespace Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
                    () => requestProductListInfo(infoOptions),
                );
            }

            if (!device.isMobile) {
                scrollToTop();
            }
        }

    }

    render() {
        const { totalItemsSubCategoryFiltered } = this.props;
        const { filterTagSelectedPage } = this.state;

        return (
            <ProductList
                totalItemsSubCategoryFiltered={totalItemsSubCategoryFiltered}
                setPageInterval={this.setPageInterval}
                filterTagSelectedPage={filterTagSelectedPage}
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
