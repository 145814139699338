import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useCallback, useEffect, useRef, useState
} from 'react';

import './MultiRangeSlider.scss';

/** @namespace Pwa/Component/MultiRangeSlider/MultirangeSlider/MultiRangeSlider */
export const MultiRangeSlider = ({
    min,
    max,
    onChange,
    selectedFilters
}) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);
    const num = 100;
    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * num),
        [min, max]
    );

    function reset() {
        setMaxVal(+max);
        setMinVal(+min);
    }

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({
            min: minVal,
            max: maxVal
        });
    }, [minVal, maxVal, onChange]);

    useEffect(() => {
        if (!selectedFilters.price) {
            reset();
        }
    }, [selectedFilters]);

    function increaseRangeValue() {
        return (event) => {
            const value = Math.max(+event.target.value, minVal + 1);
            setMaxVal(value);
            // eslint-disable-next-line no-param-reassign
            event.target.value = value.toString();
        };
    }

    function decreaseRangeValue() {
        return (event) => {
            const value = Math.min(+event.target.value, maxVal - 1);
            setMinVal(value);
            // eslint-disable-next-line no-param-reassign
            event.target.value = value.toString();
        };
    }

    return (
        <div className="PriceRange-Container">
            <div className="PriceRange-Values">
                <p>
                    { minVal }
                    €-
                </p>
                <p>
                    { maxVal }
                    €
                </p>
            </div>
            <div className="PriceRange-Input">
                <input
                  type="range"
                  min={ min }
                  max={ max }
                  value={ minVal }
                  ref={ minValRef }
                  onChange={ decreaseRangeValue() }
                  className={ classnames('thumb thumb--zindex-3', {
                      'thumb--zindex-5': minVal > max - num
                  }) }
                />
                <input
                  type="range"
                  min={ min }
                  max={ max }
                  value={ maxVal }
                  ref={ maxValRef }
                  onChange={ increaseRangeValue() }
                  className="thumb thumb--zindex-4"
                />
            </div>
            <div className="slider">
                <div className="slider__track" />
                <div ref={ range } className="slider__range" />
            </div>
        </div>
    );
};

MultiRangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedFilters: PropTypes.func.isRequired
};

export default MultiRangeSlider;
