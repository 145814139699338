/* eslint-disable max-lines */
/* eslint-disable-next-line */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Page } from '@scandipwa/scandipwa/src/component/Header/Header.config'
// import { FILTER } from 'Component/Header/Header.config';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
//import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { NavigationType } from '@scandipwa/scandipwa/src/store/Navigation/Navigation.type'
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { HistoryType, LocationType } from '@scandipwa/scandipwa/src/type/Router.type';
import { getQueryParam, setQueryParams } from 'Util/Url';
import {
    closeCategoryFilters,
    setCategoryFilter,
    setCategoryAttributeSetFilter,
    clearCategoryFilter
} from 'Store/CategoryFilters/CategoryFilters.action';

import CategoryFilterOverlay from './CategoryFilterOverlay.component';
import { toggleScroll } from '@scandipwa/scandipwa/src/util/Browser';
import { updateMeta } from 'Store/Meta/Meta.action';

/** @namespace Pwa/Component/CategoryFilterOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isInfoLoading: state.ProductListInfoReducer.isLoading,
    isProductsLoading: state.ProductListReducer.isLoading,
    totalPages: state.ProductListReducer.totalPages,
    isShowCategoryFilters: state.CategoryFiltersReducer.isShowCategoryFilters,
    selectedFilters: state.CategoryFiltersReducer.selectedFilters,
    selectedAttributeSetFilter: state.CategoryFiltersReducer.selectedAttributeSetFilter,
    category: state.CategoryReducer.category
});

/** @namespace Pwa/Component/CategoryFilterOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE)),
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(NavigationType.BOTTOM_NAVIGATION_TYPE, state)),
    closeCategoryFilters: () => dispatch(closeCategoryFilters()),
    setCategoryFilter: (payload) => dispatch(setCategoryFilter(payload)),
    setCategoryAttributeSetFilter: (payload) => dispatch(setCategoryAttributeSetFilter(payload)),
    clearCategoryFilter: (payload) => dispatch(clearCategoryFilter(payload)),
    updateMeta: (payload) => dispatch(updateMeta(payload))
});

/** @namespace Pwa/Component/CategoryFilterOverlay/Container/CategoryFilterOverlayContainer */
export class CategoryFilterOverlayContainer extends PureComponent {
    static propTypes = {
        history: HistoryType.isRequired,
        location: LocationType.isRequired,
        customFiltersValues: PropTypes.objectOf(PropTypes.array).isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        changeNavigationState: PropTypes.func.isRequired,
        availableFilters: PropTypes.objectOf(PropTypes.shape).isRequired,
        isInfoLoading: PropTypes.bool.isRequired,
        isShowCategoryFilters: PropTypes.bool.isRequired,
        selectedFilters: PropTypes.func.isRequired,
        closeCategoryFilters: PropTypes.func.isRequired,
        setCategoryFilter: PropTypes.func.isRequired,
        setCategoryAttributeSetFilter: PropTypes.func.isRequired,
        clearCategoryFilter: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        availableFiltersByAttributeSet: PropTypes.objectOf(PropTypes.array).isRequired,
        selectedAttributeSetFilter: PropTypes.objectOf(PropTypes.shape).isRequired,
        category: PropTypes.objectOf(PropTypes.shape).isRequired
    };

    containerFunctions = {
        onSeeResultsClick: this.onSeeResultsClick.bind(this),
        onResetClick: this.onResetClick.bind(this),
        onApplyClick: this.onApplyClick.bind(this),
        toggleCustomFilter: this.toggleCustomFilter.bind(this),
        getFilterUrl: this.getCustomFilterUrl.bind(this),
        onVisible: this.onVisible.bind(this),
        onHide: this.onHide.bind(this),
        closeCategoryFiltersMenu: this.closeCategoryFiltersMenu.bind(this),
        availableFiltersByAttributeSet: this.availableFiltersByAttributeSet.bind(this),
        applyFiltersButton: this.applyFiltersButton.bind(this)
    };

    availableFiltersByAttributeSet() {
        const {
            category,
            availableFilters,
            selectedAttributeSetFilter,
            setCategoryAttributeSetFilter
        } = this.props;

        if (!selectedAttributeSetFilter) {
            return availableFilters;
        }

        if (!Object.keys(selectedAttributeSetFilter).length) {
            const categoryAttributeSets = category.mc_attribute_sets;
            if (categoryAttributeSets) {
                setCategoryAttributeSetFilter(categoryAttributeSets[0]);
            }

            return availableFilters;
        }

        const availableFiltersByAttributeSet = {};

        Object.values(availableFilters).forEach(
            (availableFilter) => {
                if (selectedAttributeSetFilter.attributes.includes(availableFilter.attribute_code)) {
                    availableFiltersByAttributeSet[availableFilter.attribute_label] = availableFilter;
                }
            }
        );

        return availableFiltersByAttributeSet;
    }

    closeCategoryFiltersMenu() {
        const { closeCategoryFilters } = this.props;
        closeCategoryFilters();
        toggleScroll(true);
        const top = Math.abs(parseInt(document.body.style.marginTop, 10));
        document.body.style.marginTop = null;
        window.scrollTo(0, top);
    }

    updateFilter(filterName, filterArray) {
        const { setCategoryFilter } = this.props;
        setCategoryFilter({ filterName, filterArray });
    }

    toggleCustomFilter(requestVar, value) {
        this.updateFilter(requestVar, [value]);
    }

    getFilterUrl(filterName, filterArray, isFull = true) {
        const { location: { pathname } } = this.props;
        const selectedFilters = this._getNewSelectedFiltersString(filterName, filterArray);
        const customFilters = isFull ? `${pathname}?customFilters=` : '';
        const formattedFilters = this._formatSelectedFiltersString(selectedFilters);

        return `${customFilters}${formattedFilters}`;
    }

    getFullFilterUrl() {
        const {
            selectedFilters,
            selectedAttributeSetFilter
        } = this.props;
        const filters = [];

        const filtersArray = Object.keys(selectedFilters);

        filtersArray.forEach((selectedFilter) => {
            if (selectedAttributeSetFilter) {
                if (!selectedAttributeSetFilter.attributes.includes(selectedFilter)) {
                    return;
                }
            }

            const filterString = this._getFullNewSelectedFiltersString(selectedFilter, selectedFilters[selectedFilter]);
            filters.push(this._formatSelectedFiltersString(filterString));
        });

        const queryString = filters.join(';');
        return `${queryString}`;
    }

    _getFullNewSelectedFiltersString(filterName, filterArray) {
        const customFilers = {
            [filterName]: filterArray
        };

        return Object.entries(customFilers)
            .reduce((accumulator, [filterKey, filterValue]) => {
                if (filterValue.length) {
                    const filterValues = filterValue.sort().join(',');

                    accumulator.push(`${filterKey}:${filterValues}`);
                }

                return accumulator;
            }, [])
            .sort()
            .join(';');
    }

    getCustomFilterUrl(filterKey, value) {
        return this.getFilterUrl(filterKey, this._getNewFilterArray(filterKey, value));
    }

    _getSelectedFiltersFromUrl() {
        const { location } = this.props;
        const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

        return selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');
            return { ...acc, [key]: value.split(',') };
        }, {});
    }

    _getNewSelectedFiltersString(filterName, filterArray) {
        const prevCustomFilters = this._getSelectedFiltersFromUrl();
        const customFilers = {
            ...prevCustomFilters,
            [filterName]: filterArray
        };

        return Object.entries(customFilers)
            .reduce((accumulator, [filterKey, filterValue]) => {
                if (filterValue.length) {
                    const filterValues = filterValue.sort().join(',');

                    accumulator.push(`${filterKey}:${filterValues}`);
                }

                return accumulator;
            }, [])
            .sort()
            .join(';');
    }

    _formatSelectedFiltersString(string) {
        const hasTrailingSemicolon = string[string.length - 1] === ';';
        const hasLeadingSemicolon = string[0] === ';';

        if (hasLeadingSemicolon) {
            return this._formatSelectedFiltersString(string.slice(0, -1));
        }

        if (hasTrailingSemicolon) {
            return string.slice(1);
        }

        return string;
    }

    onResetClick() {
        const {
            hideActiveOverlay,
            goToPreviousHeaderState,
            goToPreviousNavigationState,
            clearCategoryFilter,
            updateMeta
        } = this.props;

        hideActiveOverlay();
        goToPreviousHeaderState();
        goToPreviousNavigationState();
        clearCategoryFilter();
        updateMeta({ robots: 'index, follow' });
    }

    onApplyClick() {
        const { location, history } = this.props;

        setQueryParams({
            customFilters: this.getFullFilterUrl(),
            page: ''
        }, location, history);
    }

    onSeeResultsClick() {
        const {
            hideActiveOverlay,
            goToPreviousHeaderState,
            goToPreviousNavigationState
        } = this.props;

        hideActiveOverlay();
        goToPreviousHeaderState();
        goToPreviousNavigationState();
    }

    onVisible() {
        const {
            hideActiveOverlay,
            changeHeaderState,
            changeNavigationState,
            goToPreviousNavigationState,
            location: { pathname, search }
        } = this.props;

        changeHeaderState({
            name: Page.FILTER,
            title: __('Filters'),
            onCloseClick: () => {
                hideActiveOverlay();
                goToPreviousNavigationState();
            }
        });

        changeNavigationState({
            name: Page.FILTER,
            isHidden: true
        });

        window.addEventListener('popstate', this.historyBackHook);

        history.pushState(
            { overlayOpen: true },
            '',
            pathname + search
        );
    }

    historyBackHook = () => {
        const {
            goToPreviousNavigationState,
            customFiltersValues,
            hideActiveOverlay,
            goToPreviousHeaderState
        } = this.props;

        goToPreviousNavigationState();
        hideActiveOverlay();
        // close filter only if no applied filters left
        if (Object.keys(customFiltersValues).length === 0) {
            hideActiveOverlay();
            goToPreviousHeaderState();
        }
    };

    onHide() {
        window.removeEventListener('popstate', this.historyBackHook);
    }

    getAreFiltersEmpty() {
        const { isInfoLoading, availableFilters } = this.props;

        return !isInfoLoading && (
            !availableFilters
            || !Object.keys(availableFilters).length
        );
    }

    containerProps() {
        const {
            availableFilters,
            customFiltersValues,
            isCategoryAnchor,
            isInfoLoading,
            isMatchingInfoFilter,
            isProductsLoading,
            isSearchPage,
            totalPages,
        } = this.props;

        return {
            availableFilters,
            isCategoryAnchor,
            isInfoLoading,
            isProductsLoading,
            isMatchingInfoFilter,
            isSearchPage,
            totalPages,
            customFiltersValues,
            areFiltersEmpty: this.getAreFiltersEmpty(),
            isContentFiltered: this.isContentFiltered(),
        };
    }

    isContentFiltered() {
        const { customFilters, priceMin, priceMax } = this.urlStringToObject();
        return !!(customFilters || priceMin || priceMax);
    }

    urlStringToObject() {
        const { location: { search } } = this.props;
        return search.substr(1).split('&').reduce((acc, part) => {
            const [key, value] = part.split('=');
            return { ...acc, [key]: value };
        }, {});
    }

    applyFiltersButton() {
        const {
            updateMeta
        } = this.props;

        this.onApplyClick();
        this.closeCategoryFiltersMenu();
        updateMeta({ robots: 'noindex, nofollow' });
    }

    /**
     * Returns filter array with new parameters
     *
     * @param {String} filterKey key of option
     * @param {String} value
     * @returns {Object[]}
     * @memberof CategoryShoppingOptions
     */
    _getNewFilterArray(filterKey, value) {
        const { customFiltersValues } = this.props;
        const newFilterArray = customFiltersValues[filterKey] !== undefined
            ? Array.from(customFiltersValues[filterKey])
            : [];

        const filterValueIndex = newFilterArray.indexOf(value);

        if (filterKey === 'price') { // for price filter, choose one
            return [value];
        }

        if (filterValueIndex === -1) {
            newFilterArray.push(value);
        } else {
            newFilterArray.splice(filterValueIndex, 1);
        }

        return newFilterArray;
    }

    render() {
        return (
            <CategoryFilterOverlay
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CategoryFilterOverlayContainer)
);
