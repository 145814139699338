import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// eslint-disable-next-line max-len
import ProductConfigurableAttributesContainer
    from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { setCategoryAttributeSetFilter } from 'Store/CategoryFilters/CategoryFilters.action';

import CategoryConfigurableAttributes from './CategoryConfigurableAttributes.component';

/** @namespace Pwa/Component/CategoryConfigurableAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedFilter: state.ProductListInfoReducer.selectedFilter,
    currency_code: state.ConfigReducer.currencyData.current_currency_code,
    show_product_count: state.ConfigReducer.layered_navigation_product_count_enabled,
    selectedFilters: state.CategoryFiltersReducer.selectedFilters,
    selectedAttributeSetFilter: state.CategoryFiltersReducer.selectedAttributeSetFilter,
    category: state.CategoryReducer.category
});

/** @namespace Pwa/Component/CategoryConfigurableAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setAttributeSetFilter: (payload) => dispatch(setCategoryAttributeSetFilter(payload))
});

/** @namespace Pwa/Component/CategoryConfigurableAttributes/Container/CategoryConfigurableAttributesContainer */
export class CategoryConfigurableAttributesContainer extends ProductConfigurableAttributesContainer {
    static propTypes = {
        ...ProductConfigurableAttributesContainer.propTypes,
        selectedFilters: PropTypes.func.isRequired,
        selectAttributeSetFilter: PropTypes.func.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions,
        isSelected: this.isAttributeValueSelected.bind(this),
        isAttributeSetSelected: this.isAttributeSetSelected.bind(this),
        getCategoryAttributeSets: this.getCategoryAttributeSets.bind(this),
        getCategoryAttributeName: this.getCategoryAttributeName.bind(this)
    };

    getCategoryAttributeSets() {
        const {
            category
        } = this.props;

        if (!category || !category.id) {
            return [];
        }

        const categoryAttributeSets = category.mc_attribute_sets;

        if (!categoryAttributeSets) {
            return [];
        }

        return categoryAttributeSets;
    }

    isAttributeSetSelected(attributeSet) {
        const { selectedAttributeSetFilter } = this.props;

        return selectedAttributeSetFilter.name === attributeSet.name;
    }

    getCategoryAttributeName(attributeSet) {
        return attributeSet.name.replace('Pim ', '');
    }

    isAttributeValueSelected(attribute) {
        const { selectedFilters } = this.props;

        return selectedFilters[attribute.attribute_code]
            && selectedFilters[attribute.attribute_code].includes(attribute.attribute_value);
    }

    render() {
        return (
            <CategoryConfigurableAttributes
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryConfigurableAttributesContainer);
