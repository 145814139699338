/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductList from 'Component/ProductList';
import {
    CategoryProductListContainer as SourceCategoryProductListContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CategoryProductList/CategoryProductList.container';

import './CategoryProductList.override.style';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Component/CategoryProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    pushProductImpressions: (products, list) => GTMDispatcher.then(
        ({ default: dispatcher }) => dispatcher.pushProductImpressions(dispatch, products, list)
    )
});

/** @namespace Pwa/Component/CategoryProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    category: state.CategoryReducer.category
});

/** @namespace Pwa/Component/CategoryProductList/Container/CategoryProductListContainer */
export class CategoryProductListContainer extends SourceCategoryProductListContainer {
    static propTypes = {
        pageSize: PropTypes.number,
        isPaginationEnabled: PropTypes.bool
    };

    static defaultProps = {
        ...SourceCategoryProductListContainer.defaultProps,
        pageSize: 24,
        isPaginationEnabled: true
    };

    componentDidMount() {
        const { pages, category: { name: list = 'search result' }, pushProductImpressions } = this.props;

        const products = Object.keys(pages).length ? pages[Object.keys(pages)[0]] : [];

        if (products.length > 0) {
            pushProductImpressions(products, list);
        }
    }

    componentDidUpdate(prevProps) {
        const { pages: newPages, category: { name: newList = 'search result' }, pushProductImpressions } = this.props;
        const { pages: oldPages } = prevProps;

        const oldProducts = oldPages && Object.keys(oldPages).length ? oldPages[Object.keys(oldPages)[0]] : [];
        const newProducts = newPages && Object.keys(newPages).length ? newPages[Object.keys(newPages)[0]] : [];

        const { sku: oldFirstSku } = oldProducts[0] || {};
        const { sku: newFirstSku } = newProducts[0] || {};
        if (newFirstSku !== oldFirstSku) {
            pushProductImpressions(newProducts, newList);
        }
    }

    containerProps(){

        return {
            ...super.containerProps(),
            mix: {
                block: 'CategoryProductList',
                mods: {
                    layout: this.getLayout(),
                    promo: this.getPromo()
                }
            }
        };
    }

    getPromo() {
        const { promo } = this.props;

        return promo;
    }

    render() {
        return (
            <ProductList
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
