import Image from '@scandipwa/scandipwa/src/component/Image';
import Link from '@scandipwa/scandipwa/src/component/Link';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CategoryNavItem.style';

/** @namespace Pwa/Component/CategoryNav/CategoryNavItem/Component/CategoryNavItemComponent */
export class CategoryNavItemComponent extends PureComponent {
    static propTypes = {
        url: PropTypes.string.isRequired,
        photo: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        product_url: PropTypes.string.isRequired,
        category_url: PropTypes.string.isRequired
    };

    render() {
        const {
            url,
            photo,
            name,
            product_url,
            category_url
        } = this.props;

        // Task #260: Ricavo l'url del dettaglio prodotto solo sulla categoria "Divani" e sulle sue sottocategorie
        const isDivani = category_url.includes('divani');

        return (
            <div block="CategoryNavItem">
                <Link to={ isDivani && product_url ? product_url : url || '' } block="CategoryNavItem" elem="Link">
                    <Image src={ photo } ratio="custom" />
                    <p block="CategoryNavItem" elem="Name">{ name }</p>
                </Link>
            </div>
        );
    }
}

export default CategoryNavItemComponent;
