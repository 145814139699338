/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';

import { FILTER_TAG_PRODUCT_CARD_PAGE_SIZE } from 'Component/ProductCard/ProductCard.config';
import ProductListPage from 'Component/ProductListPage';
import { ProductListComponent as SourceProductList } from 'SourceComponent/ProductList/ProductList.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import './ProductList.style';

/** @namespace Pwa/Component/ProductList/Component/ProductListComponent */
export class ProductListComponent extends SourceProductList {
    static PropTypes = {
        ...SourceProductList.props,
        totalItemsSubCategoryFiltered: PropTypes.number,
        filterTagSelectedPage: PropTypes.number,
        setPageInterval: PropTypes.func
    };

    static defaultProps = {
        ...SourceProductList.defaultProps,
        filterTagSelectedPage: 1
    };

    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp
        } = this.props;

        const {
            items = [],
            // keys = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = super._processProps(props);

        return (
            <>
                <ProductListPage
                  key={ key }
                  isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
                  updatePages={ loadPage }
                  isLoading={ isLoading }
                  isVisible={ isVisible }
                  mix={ mix }
                  items={ items }
                  pageNumber={ pageNumber }
                  selectedFilters={ selectedFilters }
                  wrapperRef={ wrapperRef }
                  isPlp={ isPlp }
                />
                { this.renderFilterTagPagination() }
            </>
        );
    }

    renderFilterTagPagination() {
        const { totalItemsSubCategoryFiltered } = this.props;
        if ((totalItemsSubCategoryFiltered === undefined)
            || (totalItemsSubCategoryFiltered === null) || (totalItemsSubCategoryFiltered === 0)) {
            return null;
        }
        const pagesLength = Math.ceil(totalItemsSubCategoryFiltered / FILTER_TAG_PRODUCT_CARD_PAGE_SIZE);

        if (pagesLength > 1) {
            return (
                <div className="FilterTagPagination">
                    { /* TOTALE ELEMENTI TROVATI: */ }
                    { /* { totalItemsSubCategoryFiltered } */ }
                    { this.displayPrevArrow() }
                    { this.displayPages(pagesLength) }
                    { this.displayFollowArrow() }
                </div>
            );
        }

        return null;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component,consistent-return
    displayPrevArrow() {
        const { filterTagSelectedPage } = this.props;
        let className;
        if (filterTagSelectedPage > 1) {
            className = 'FilterTagPagination-Icon FilterTagPagination-Icon-Prev';
        } else {
            className = 'FilterTagPagination-Icon FilterTagPagination-Icon-Prev FilterTagPagination-Icon-Hidden';
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/control-has-associated-label,jsx-a11y/interactive-supports-focus
            <div
              role="button"
              className={ className }
              onClick={ (e) => {
                  this.localSetPageInterval(e, filterTagSelectedPage - 1);
              } }
            />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component,consistent-return
    displayFollowArrow() {
        const { filterTagSelectedPage } = this.props;
        let className;
        if (filterTagSelectedPage > 1) {
            className = 'FilterTagPagination-Icon FilterTagPagination-Icon-Follow FilterTagPagination-Icon-Hidden';
        } else {
            className = 'FilterTagPagination-Icon FilterTagPagination-Icon-Follow';
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/control-has-associated-label,jsx-a11y/interactive-supports-focus
            <div
              role="button"
              className={ className }
              onClick={ (e) => {
                  this.localSetPageInterval(e, filterTagSelectedPage + 1);
              } }
            />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    localSetPageInterval(e, i) {
        const { setPageInterval } = this.props;
        setPageInterval(i);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    displayPages(pagesLength) {
        const { filterTagSelectedPage } = this.props;
        const pageNumbers = [];
        for (let i = 1; i <= pagesLength; i++) {
            const isCurrent = (i === filterTagSelectedPage) ? 'isCurrentPageNumber' : '';
            const id = `FilterTagPagination-PageNumber-id-${i}`;
            const className = 'FilterTagPagination-PageNumber';
            pageNumbers.push(
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div
                  role="button"
                  className={ className }
                  id={ id }
                  onClick={ (e) => {
                      this.localSetPageInterval(e, i);
                  } }
                >
                    <span className={ isCurrent }>{ i }</span>
                </div>
            );
        }

        return pageNumbers;
    }

    renderNoProducts() {
        return (
            <div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                     <h3>{ __('There were no products available for this category.') }</h3>
                    {/* <h2>{ __('We are sorry!') }</h2>
                    <h3>{ __('There were no products found matching your request.') }</h3>
                    <p>{ __('Please, try removing selected filters and try again!') }</p> */}
                </div>
            </div>
        );
    }

    render() {
        const {
            totalPages,
            isLoading,
            mix,
            layout
        } = this.props;

        if (!isLoading && totalPages === 0) {
            setLoadedFlag();
            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
              layout={ layout }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default ProductListComponent;
